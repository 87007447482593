import React from "react";
import SanityImage from "gatsby-plugin-sanity-image"

const Image =({image, alt, className, slider })=> {
  return (
    <>
      {slider ? (
        <div className={className}>
          {!!image &&
            <SanityImage {...image} alt={alt} width={900} />
          }
        </div>
      )
      :
        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className={className}>
          {!!image &&
            <SanityImage {...image} alt={alt} width={900} />
          }
        </div>
      }
    </>

  )
}

export default Image
